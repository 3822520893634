export const dirtyFolder = '_dirty'

export const knownFiles = [
    '_commonredist',
    '.egstore',
    'installers',
    'redistributables',
    'update',
    'x64',
    'bink2w64.dll',
    'common.rpf',
    'd3dcompiler_46.dll',
    'd3dcsx_46.dll',
    'eossdk-win64-shipping.dll',
    'gfsdk_shadowlib.win64.dll',
    'gfsdk_txaa.win64.dll',
    'gfsdk_txaa_alpharesolve.win64.dll',
    'gpuperfapidx11-x64.dll',
    'gta5.exe',
    'gtavlanguageselect.exe',
    'gtavlauncher.exe',
    'index.bin',
    'nvpmapi.core.win64.dll',
    'playgtav.exe',
    'version.txt',
    'steam_api64.dll',
    'x64a.rpf',
    'x64b.rpf',
    'x64c.rpf',
    'x64d.rpf',
    'x64e.rpf',
    'x64f.rpf',
    'x64g.rpf',
    'x64h.rpf',
    'x64i.rpf',
    'x64j.rpf',
    'x64k.rpf',
    'x64l.rpf',
    'x64m.rpf',
    'x64n.rpf',
    'x64o.rpf',
    'x64p.rpf',
    'x64q.rpf',
    'x64r.rpf',
    'x64s.rpf',
    'x64t.rpf',
    'x64u.rpf',
    'x64v.rpf',
    'x64w.rpf',

    dirtyFolder,
]
